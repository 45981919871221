const messages = {
  'aboutLJ.founderOfMSF':
    'La Jardinera é a fundadora da organização internacional Mãos Sem Fronteiras e la precursora da técnica de Estimulação Neural.',
  'aboutLJ.founder.first': 'Fundadora',
  'aboutLJ.founder.second': 'da organização internacional',
  'aboutLJ.founder.third': 'Mãos Sem Fronteiras',
  'aboutLJ.founder.fourth': 'e la precursora da técnica de',
  'aboutLJ.founder.fifth': 'Estimulação Neural.',
  'aboutLJ.5Minutes':
    'É a força motriz por trás da campanha “5 Minutos, Eu Medito”, que levou a meditação a milhares de pessoas.',
  'aboutLJ.lifework.first':
    'Ela dedica sua vida a melhorar o bem-estar das pessoas',
  'aboutLJ.lifework.second':
    'e como parte dessa missão envia mensagens com histórias, lições de vida, conselhos e amor que nos motivam a viver uma vida mais leve ...',
  'aboutLJ.messages':
    'Motiva-nos a viver uma vida guiada pelo nosso coração, sempre atentos aos que nos rodeiam e ao potencial que temos para ajudar.',

  'altText.home.LJ': 'Foto de La Jardinera na Birmânia',
  'altText.home.msfLogo': 'Logo Mãos Sem Fronteiras',
  'altText.home.institutoLogo':
    'Logo do Instituto Internacional de Estimulação Neural',
  'altText.home.euMeditoLogo': 'Logo da campanha: 5 minutos para meditar',

  'contact.name': 'nome',
  'contact.category': 'Categoria',
  'contact.email': 'mail',
  'contact.message': 'mensagem',
  'contact.number': 'número',
  'contact.submit': 'enviar',
  'contact.worldMap': 'MSF no mundo',

  'contact.category1': 'reflexões',
  'contact.label.category1': 'reflexões',
  'contact.category2': 'pregunta',
  'contact.label.category2': 'pregunta',
  'contact.category3': 'pedir algo',
  'contact.label.category3': 'pedir algo',
  'contact.select': 'escolha...',

  'footer.manos.label': 'MSF_int',
  'footer.manos.altText': 'Mundo monocromático',
  'footer.yoMedito.label': 'Eu Medito',
  'footer.yoMedito.altText': 'Balão monocromático',
  'footer.laJardinera.label': 'La Jardinera',
  'footer.laJardinera.altText': 'Flor monocromático',
  'footer.copyright': '© 2025 Mãos Sem Fronteiras',

  'home.content.title': 'La Jardinera',
  'home.title.phrase': 'A sabedoria está na simplicidade',

  'home.buttons.phraseCorner': 'escute',
  'home.buttons.corner': 'o canto da felicidade da Jardinera',
  'home.buttons.phraseMessages': 'leia e escute',
  'home.buttons.messages': 'mensagens da Jardinera',

  'home.discover.title': 'saiba mais sobre:',
  'home.discoverMore.msf': 'Estimulação Neural',
  'home.discoverMore.msf2': 'y Mãos Sem Fronteiras',
  'home.discoverMore.instituto': 'Instituto de Estimulação Neural',
  'home.discoverMore.euMedito': '5 Minutos,',
  'home.discoverMore.euMedito2': 'Eu Medito',

  'home.writeToLJ.text':
    'você tem dúvidas ou quer compartilhar suas reflexões com La Jardinera?',

  'messagesFromLJ.title': 'Mensagens',
  'messagesFromLJ.subTitle': 'da Jardinera',

  'legal.address.label': 'Endereço Registrado',
  'legal.address.line1': 'Manos Sin Fronteras Internacionales',
  'legal.address.line2': 'C/ Ribargorza no. 18',
  'legal.address.line3': 'Entresuelo B de Zaragoza',
  'legal.address.line4': '50003 (Zaragoza)',
  'legal.contact.email': 'Email: ',
  'legal.contact.phone': 'Telefone: +34 658 77 46 78',
  'legal.contact.website': 'Site: ',
  'legal.representative.label': 'Representante Legal',
  'legal.representative.name': 'Lucía Peña',
  'legal.representative.with': 'Registro: Diputación General de Aragón',
  'legal.representative.number': 'Numero de Registro: 01-Z-2070-2007',
  'legal.representative.code': 'Código de Identificação Definitivo: G99197394',
  'legal.disclaimer.label': 'Declaração Legal',
  'legal.disclaimer.text':
    'O conteúdo deste web site está baseado em normas da Espanha, salvo indicação expressa em contrário. As opiniões e critérios publicados nos artigos representam os autores e não o Blog de Mãos Sem Fronteiras Internacional e seus criadores. Se existir alguma sugestão ou reclamação a respeito do que foi publicado aqui, agradecemos que se dirija à área "Entre em contato conosco"  para atender seu comunicado. Todos os artigos publicados aqui tem o consentimento expresso de seu autor.',

  'locale.en': 'EN',
  'locale.es': 'ES',
  'locale.it': 'IT',
  'locale.pt': 'PT',

  'meta.description.messages':
    'Leia as mensagens que La Jardinera compartilha com o mundo, por trás de cada uma está uma oportunidade de crescimento e felicidade.',
  'meta.description.contactUs':
    'Quer compartilhar suas experiências e conhecimentos? Tem comentários ou perguntas sobre o site ou os artigos que publicamos? Escreva-nos uma mensagem e responderemos em seguida.',
  'meta.description.home':
    'Bem-vindo ao blog La Jardinera! Convidamos você a ler as mensagens que La Jardinera nos envia com muito amor; histórias, eventos e experiências para refletir e aprender.',
  'meta.description.happinessCorner':
    'Entre no canto da Felicidade de La Jardinera. Ouça mensagens curtas com conselhos sobre como ser feliz.',

  'menu.messages': 'Mensagens',
  'menu.happinessCorner': 'Cantinho da felicidade',
  'menu.messageLJ': 'Escreva para LJ',
  'menu.home': 'Home',
  'menu.legal': 'Representante Legal',

  'sidePanel.estimulacionNeural.altText': 'Estimulação Neural',
  'sidePanel.estimulacionNeural.text':
    'Uma técnica para o seu bem-estar físico, mental e emocional.',
  'sidePanel.estimulacionNeural.title': 'Estimulação Neural',
  'sidePanel.laJardinera.text':
    'Precurssora da Estimulação Neural dedicou sua vida para ajudar o planeta.',
  'sidePanel.yoMedito.altText': 'Balão - Eu Medito',
  'sidePanel.yoMedito.text': 'Quando você tem paz, o mundo tem paz.',
  'sidePanel.yoMedito.title': 'Eu Medito',

  'template.comments': 'Escreva seus comentários',
};

export default messages;
