import React from 'react';

import { ReactComponent as FacebookIcon } from '../../images/svgs/facebook-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../images/svgs/youtube-icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/svgs/instagram-icon.svg';

import styles from './socialMedia.module.scss';

const SocialMedia = () => (
  <div className={styles.socialMedia}>
    <a
      className={styles.socialLink}
      href="https://www.facebook.com/LJardinera"
      rel="noreferrer"
      target="_blank"
    >
      <FacebookIcon />
      <span className={styles.facebookColor}>@LJardinera</span>
    </a>
    <a
      className={styles.socialLink}
      href="https://www.instagram.com/frases.lajardinera"
      rel="noreferrer"
      target="_blank"
    >
      <InstagramIcon />
      <span className={styles.instagramColor}>@la_jardinera_msf</span>
    </a>
    <a
      className={styles.socialLink}
      href="https://www.youtube.com/c/LaJardinera"
      rel="noreferrer"
      target="_blank"
    >
      <YoutubeIcon />
      <span className={styles.youtubeColor}>LaJardinera</span>
    </a>
  </div>
);

export default SocialMedia;
