const messages = {
  'aboutLJ.founderOfMSF':
    'La Jardinera is the founder of the international organization Manos Sin Fronteras (Hands Without Borders) and forerunner of the technique: Neural Stimulation.',
  'aboutLJ.founder.first': 'Founder',
  'aboutLJ.founder.second': 'of the international organization',
  'aboutLJ.founder.third': 'Manos Sin Fronteras',
  'aboutLJ.founder.fourth':
    '(Hands Without Borders) and forerunner of the technique:',
  'aboutLJ.founder.fifth': 'Neural Stimulation.',
  'aboutLJ.5Minutes':
    'She is the driving force behind the campaign “5 Minutes, I Meditate”, which has brought meditation to thousands of people.',
  'aboutLJ.lifeWork':
    'La Jardinera has dedicated her life to improving the well-being of people and as part of this mission she sends messages with stories, life lessons, with advice, with love that motivate us to live a lighter life...  ',
  'aboutLJ.lifework.first':
    'Ha dedicado su vida a mejorar el bienestar de las personas',
  'aboutLJ.lifework.second':
    'y como parte de esta misión envía mensajes con historias, lecciones de vida, con consejos, con amor que nos motivan a vivir una vida más ligera...',
  'aboutLJ.messages':
    'A life guided by our heart, always aware of those around us and the potential we have to help.',

  'altText.home.LJ': 'Picture of La Jardinera in Burma',
  'altText.home.msfLogo': 'MSF Logo',
  'altText.home.institutoLogo':
    'Logo for the International Institute of Neural Stimulation',
  'altText.home.euMeditoLogo': 'Logo for the campaign: 5 Minutes I Meditate',

  'contact.name': 'Name',
  'contact.category': 'Category',
  'contact.email': 'Email',
  'contact.message': 'Message',
  'contact.number': 'Number',
  'contact.submit': 'Send',
  'contact.worldMap': 'MSF Worldwide',

  'contact.category1': 'share your thoughts',
  'contact.label.category1': 'share your thoughts',
  'contact.category2': 'questions',
  'contact.label.category2': 'questions',
  'contact.category3': 'requests',
  'contact.label.category3': 'requests',
  'contact.select': 'Select...',

  'footer.manos.label': 'MSF_int',
  'footer.manos.altText': 'Monochrome World',
  'footer.yoMedito.label': 'I Meditate',
  'footer.yoMedito.altText': 'Monochrome Balloon',
  'footer.laJardinera.label': 'La Jardinera',
  'footer.laJardinera.altText': 'Monochrome Flower',
  'footer.copyright': '© 2025 Manos Sin Fronteras',

  'home.content.title': 'La Jardinera',
  'home.title.phrase': 'Wisdom Lies in Simplicity',

  'home.buttons.corner': "LJ's Corner of Happiness",

  'home.buttons.phraseCorner': 'Listen',
  'home.buttons.phraseMessages': 'Listen and Read',
  'home.buttons.messages': 'Messages from La Jardinera',

  'home.discover.title': 'Discover more about:',
  'home.discoverMore.msf': 'Neural Stimulation',
  'home.discoverMore.msf2': 'and Hands Without Borders',
  'home.discoverMore.instituto': 'Institute of Neural Stimulation',
  'home.discoverMore.euMedito': '5 Minutos,',
  'home.discoverMore.euMedito2': 'I Meditate',

  'home.writeToLJ.text':
    'Do you have questions or want to share your thoughts with La Jardinera?',

  'messagesFromLJ.title': 'Messages',
  'messagesFromLJ.subTitle': 'from La Jardinera',

  'legal.address.label': 'Registered Address',
  'legal.address.line1': 'Manos Sin Fronteras Internacionales',
  'legal.address.line2': 'C/ Ribargorza no. 18',
  'legal.address.line3': 'Entresuelo B de Zaragoza',
  'legal.address.line4': '50003 (Zaragoza)',
  'legal.contact.email': 'E-mail: ',
  'legal.contact.phone': 'Tel: +34 658 77 46 78',
  'legal.contact.website': 'Website: ',
  'legal.representative.label': 'Legal Representative',
  'legal.representative.name': 'Lucía Peña',
  'legal.representative.with': 'Registered with: Diputación General de Aragón',
  'legal.representative.number': 'Register number: 01-Z-2070-2007',
  'legal.representative.code': 'Identification code: G99197394',
  'legal.disclaimer.label': 'Disclaimer',
  'legal.disclaimer.text':
    'The content of this website is based on Spanish norms, unless otherwise stated. The opinions published in these articles represent the views of the authors and not those of the Manos Sin Fronteras Blog or its creators. If there are any suggestions or complaints with regards with anything published, we would appreciate you letting us know through the contact us section. Every article published here has the express consent of its author.',

  'locale.en': 'EN',
  'locale.es': 'ES',
  'locale.it': 'IT',
  'locale.pt': 'PT',

  'meta.description.messages':
    'Read the messages La Jardinera shares with the world, behind each one lies an opportunity for growth and happiness.',
  'meta.description.messageLJ':
    'Would you like to share your thoughts or experiences with La Jardinera? Leave a message that will arrive directly to her.',
  'meta.description.home':
    "Welcome to La Jardinera's blog! We invite you to read the messages that La Jardinera sends us with a lot of love; stories, events and experiences to reflect and learn.",
  'meta.description.happinessCorner':
    "Step into La Jardinera's corner of Happiness. Listen to short messages with advice on how to be happy.",

  'menu.messages': 'Messages',
  'menu.happinessCorner': 'A Corner of Happiness',
  'menu.messageLJ': 'Message LJ',
  'menu.home': 'Home',
  'menu.legal': 'Legal Notice',

  'template.comments': 'Write your Comments',
};

export default messages;
